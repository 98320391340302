import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert, Input, Tag } from '@pluggyai/ui'
import { Option } from '@pluggyai/ui/dist/components/Dropdown/Dropdown.types'
import type { Connector } from 'pluggy-js'
import { Loader } from 'semantic-ui-react'

import { snakeCaseToText } from '../../../utils/string'
import { IsDisabledPopup } from '../IsDisabledPopup'
import { Props } from './ConnectorsCustomizationForm.types'
import {
  areConnectorsNewAndInType,
  getConnectorTypes,
  isNewConnector,
  isOpenFinanceConnector,
  sortConnectorsByUsage,
} from './utils'

import './ConnectorsCustomizationForm.css'

export type ConnectorItem = Pick<
  Connector,
  'id' | 'name' | 'type' | 'createdAt'
> & {
  selected: boolean
}

const ConnectorsCustomizationForm = ({
  connectors,
  isLoading,
  error,
  isFreeSubscription,
  isTeamOwnerOrAdminRole,
  handleSelectedConnectorsIdsChange,
  connectorIds = [],
}: Props) => {
  const { t } = useTranslation()

  const connectorsSorted = useMemo(
    () => (connectors ? sortConnectorsByUsage(connectors) : null),
    [connectors],
  )

  const selectedConnectors = connectorsSorted?.filter((connector) =>
    connectorIds.includes(connector.id),
  )

  const handleConnectorsChange = useCallback(
    ({
      connectorType,
      selectedConnectorIds,
    }: {
      connectorType: Connector['type']
      selectedConnectorIds: Connector['id'][]
    }) => {
      if (connectorsSorted === null) {
        return
      }

      const previousConnectorsWithoutSelectedType: Connector[] = connectorIds
        .map((connectorId) =>
          connectorsSorted.find((connector) => connector.id === connectorId),
        )
        .filter(
          (connector): connector is Connector =>
            connector !== undefined && connector.type !== connectorType,
        )

      const newConnectorIdsOfType = selectedConnectorIds
        .map((connectorId) =>
          connectorsSorted.find((connector) => connector.id === connectorId),
        )
        .filter((connector) => connector?.type === connectorType)

      // set new connector ids as the changed connectors in dropdown type
      // plus the previous connectors of the other types.
      const newConnectorIds = [
        ...previousConnectorsWithoutSelectedType,
        ...newConnectorIdsOfType,
      ].map((connector) => connector?.id)

      if (!isTeamOwnerOrAdminRole) {
        // if user is MEMBER role (doesn't have access to make changes), don't allow it
        return
      }

      handleSelectedConnectorsIdsChange(newConnectorIds as Connector['id'][])
    },
    [
      connectorIds,
      connectorsSorted,
      handleSelectedConnectorsIdsChange,
      isTeamOwnerOrAdminRole,
    ],
  )

  return (
    <div className={'ConnectorsCustomizationForm'}>
      {error && <Alert size={'medium'} type={'error'} message={error} />}
      <p>{t('customization.form.connectors-list.subtitle')}</p>
      {isLoading ? (
        <div className={'widget-section-loader'}>
          <Loader size="big" active />
        </div>
      ) : (
        // TODO extract to ConnectorsList component (PR comment: https://github.com/pluggyai/dashboard/pull/72#discussion_r806160829)
        <div className={'connectors-list'}>
          {getConnectorTypes(connectors).map((connectorType) => {
            const selectedOptions = selectedConnectors
              ?.filter((connector) => connector.type === connectorType)
              ?.map((connector) => {
                const { id, name } = connector
                return {
                  id,
                  name: isOpenFinanceConnector(connector)
                    ? `${name} [OF]`
                    : name,
                }
              })

            const connectorOptions: Option[] | undefined = connectorsSorted
              ?.filter(
                (connector) =>
                  connector.type === connectorType && connector.id !== 212, // Avoid Nubank 212 to be selected, users should migrate to OF
              )
              .map((connector) => {
                const { id, name } = connector

                const newTag = isNewConnector(connector) && (
                  <Tag
                    size="small"
                    text={t(
                      'customization.form.connectors-list.form.general.field.connectors.new',
                    )}
                  />
                )

                const ofTag = isOpenFinanceConnector(connector) && (
                  <Tag
                    size="small"
                    text={t(
                      'customization.form.connectors-list.form.general.tag.of',
                    )}
                  />
                )

                return {
                  id,
                  name,
                  isDisabled: isFreeSubscription || !isTeamOwnerOrAdminRole,
                  children: ofTag || newTag,
                }
              })
            const fallbackConnectorTypeLabel = snakeCaseToText(connectorType)

            return (
              <div
                className={`connectors-list-type ${connectorType}`}
                key={connectorType}
              >
                <span className={'connector-type-label'}>
                  <Trans
                    i18nKey={`customization.form.connectors-list.form.general.connectorTabs.${connectorType}`}
                    defaults={fallbackConnectorTypeLabel}
                  />
                  {areConnectorsNewAndInType(
                    connectorsSorted,
                    connectorType,
                  ) && <div className={'new-connectors-indicator'} />}
                </span>
                <IsDisabledPopup
                  isFreeSubscription={isFreeSubscription}
                  isTeamMemberRole={!isTeamOwnerOrAdminRole}
                >
                  <Input
                    type={'select'}
                    options={connectorOptions}
                    label={t(
                      'customization.form.connectors-list.form.general.field.title',
                    )}
                    value={selectedOptions}
                    onChange={({ value }: { value: Option | Option[] }) => {
                      const selectedConnectorOptions = value as Option[]

                      const selectedConnectorIds = selectedConnectorOptions.map(
                        (option) => option.id as Connector['id'],
                      )

                      handleConnectorsChange({
                        connectorType: connectorType as Connector['type'],
                        selectedConnectorIds,
                      })
                    }}
                    multiple
                    withSelectAllOption={!isFreeSubscription}
                    selectAllLabel={t(
                      'customization.form.connectors-list.form.general.field.connectors.select-all',
                    )}
                  />
                </IsDisabledPopup>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default React.memo(ConnectorsCustomizationForm)
